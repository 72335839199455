.vis-tooltip {
  position: absolute;
  max-width: 300px;
  background-color: white;
  outline: 1px solid #ccc;
  border-radius: 15px;
  padding: 10px;
}

.vis-container {
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: white;
  height: 100%;
  max-height: 73vh;
}