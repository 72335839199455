@tailwind base;
@tailwind components;
@tailwind utilities;


ol,
ul {
  list-style-position: inside;
  margin-left: 0.5rem;
  /* Adjust as needed */
  padding-left: 1rem;
  /* Adjust as needed */
}

ul.list-disc>li {
  list-style: disc;
}

ol.list-decimal>li {
  list-style: decimal;
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin-left: 0.5rem;
  /* Adjust for nested lists */
  padding-left: 1rem;
  /* Adjust for nested lists */
}

li {
  margin-bottom: 0.5rem;
  /* Adjust space between list items */
}

.custom-overflow-wrap {
  overflow-wrap: anywhere;
}

.swiper-pagination-bullet {
  background-color: #ffffff;
  /* Customize the bullet color */
}

.swiper-pagination-bullet-active {
  background-color: #ffffff;
  /* Customize the active bullet color */
}

.swiper-button-next,
.swiper-button-prev {
  /* Customize the navigation button color */
  color: #4a4a4a;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: #ffffff;
  /* Customize the hover color */
}

.responsive-table {
  /* display: block; */
  width: 100% !important;
  overflow-x: hidden;
}

.responsive-table table {
  width: 100% !important;
  table-layout: fixed;
  /* table-layout: fixed; */
  /* Ensure table cells don't exceed their parent */
}

.responsive-table td,
.responsive-table th,
.responsive-table tr,
.responsive-table tbody {
  word-wrap: break-word;
  white-space: normal;
  width: 100% !important;
  /* Ensure text wraps */
}

.responsive-table th .expander {
  display: none;
  /* Hide the expand/collapse button */
}

.responsive-table center {
  min-width: auto !important;
}

.responsive-table img {
  max-width: 100%;
  /* Ensure images are responsive */
  height: auto;
}

.icon-2xl {
  stroke-width: 1.5;
  flex-shrink: 0;
  height: 2rem;
  width: 2rem;
}

.swiper-pagination-bullet {
  background-color: #c6c6c6 !important;
  /* Customize the bullet color */
}

.swiper-pagination-bullet-active {
  background-color: #ffffff !important;
  /* Customize the active bullet color */
}

.swiper-button-next,
.swiper-button-prev {
  /* Customize the navigation button color */
  color: #101c2c !important;
  border-radius: 100%;
  /* padding: 16px; */
  background-color: #dad9d9;
  opacity: 0.6 !important;
  width: 60px !important;
  height: 60px !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: #4a4a4a !important;
  opacity: 1 !important;
  /* Customize the hover color */
}